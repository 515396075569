.countdown-timer-wrapper {
	border-radius: 5px;
	margin-bottom: 20px;
	max-width: 500px;
	margin: 30px auto;

	&.countdown-timer-wrapper1 {
		margin: 0px auto;
	}

	h5 {
		font-size: 14px;
		letter-spacing: 0.5px;
		text-align: center;
		padding-top: 10px;
		text-shadow: none;
	}

	.timer {
		padding: 10px;
		text-align: center;
		padding-top: 15px;

		.timer-wrapper {
			display: inline-block;
			width: 110px;
			height: 50px;

			.time {
				font-size: 35px;
				font-weight: bold;
				color: $white;
				width: 80px;
				height: 80px;
				border-radius: 7px;
				line-height: 2.3;
				text-align: center;
				margin: 0 auto;
				margin-top: 15px !important;
				margin-bottom: 5px;
				position: relative;
				overflow: hidden;
			}
		}
	}

	&.countdown-timer-wrapper1 .timer .timer-wrapper {
		.time {
			background: $black-2;
			color: $white;
		}

		.text {
			color: $white;
		}
	}

	.timer .timer-wrapper .text {
		font-size: 20px;
		color: $color;
		padding-top: 4px;
	}
}

@media (mx-width: 568px) {
	.countdown-timer-wrapper .timer .timer-wrapper {
		margin-top: 20px !important;

		.time {
			margin-top: 20px !important;
		}
	}
}