/**
 * pretty-checkbox.css
 *
 *
 */

.pretty {
	* {
		box-sizing: border-box;
	}

	input {
		&:not([type=checkbox]):not([type=radio]) {
			display: none;
		}

		position: absolute;
		left: 0;
		top: 0;
		min-width: 1em;
		width: 100%;
		height: 100%;
		z-index: 2;
		opacity: 0;
		margin: 0;
		padding: 0;
		cursor: pointer;
	}

	position: relative;
	display: inline-block;
	margin-right: 1em;
	white-space: nowrap;
	line-height: 1;

	.state {
		label {
			position: initial;
			display: inline-block;
			font-weight: 400;
			margin: 0;
			text-indent: 1.5em;
			min-width: calc(1em + 2px);

			&:after {
				content: '';
				width: calc(1em + 2px);
				height: calc(1em + 2px);
				display: block;
				box-sizing: border-box;
				border-radius: 0;
				border: 1px solid transparent;
				z-index: 0;
				position: absolute;
				left: 0;
				top: calc((0% - (100% - 1em)) - 8%);
				background-color: transparent;
			}

			&:before {
				content: '';
				width: calc(1em + 2px);
				height: calc(1em + 2px);
				display: block;
				box-sizing: border-box;
				border-radius: 0;
				border: 1px solid transparent;
				z-index: 0;
				position: absolute;
				left: 0;
				top: calc((0% - (100% - 1em)) - 8%);
				background-color: transparent;
				border-color: $white-3;
			}
		}

		&.p-is-hover,
		&.p-is-indeterminate {
			display: none;
		}
	}

	&.p-default {
		&.p-fill .state label:after {
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
		}

		.state label:after {
			-webkit-transform: scale(0.6);
			-ms-transform: scale(0.6);
			transform: scale(0.6);
		}

		input:checked~.state label:after {
			background-color: $white-3 !important;
		}

		&.p-thick .state label {

			&:after,
			&:before {
				border-width: calc(1em / 7);
			}

			&:after {
				-webkit-transform: scale(0.4) !important;
				-ms-transform: scale(0.4) !important;
				transform: scale(0.4) !important;
			}
		}
	}

	&.p-icon {
		.state .icon1 {
			position: absolute;
			font-size: 1em;
			width: calc(1em + 2px);
			height: calc(1em + 2px);
			left: 0;
			z-index: 1;
			text-align: center;
			line-height: normal;
			top: calc((0% - (100% - 1em)) - 8%);
			border: 1px solid $white-3;
			color: $white;
			opacity: 0;

			&:before {
				margin: 0;
				width: 100%;
				height: 100%;
				text-align: center;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-flex: 1;
				-ms-flex: 1;
				flex: 1;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				line-height: 1;
			}
		}

		input:checked~.state {
			.icon1 {
				opacity: 1;
			}

			label:before {
				border-color: $white-3;
			}
		}
	}

	&.p-svg {
		.state {
			.svg {
				position: absolute;
				font-size: 1em;
				width: calc(1em + 2px);
				height: calc(1em + 2px);
				left: 0;
				z-index: 1;
				text-align: center;
				line-height: normal;
				top: calc((0% - (100% - 1em)) - 8%);
				border: 1px solid transparent;
				opacity: 0;
			}

			svg {
				margin: 0;
				width: 100%;
				height: 100%;
				text-align: center;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-flex: 1;
				-ms-flex: 1;
				flex: 1;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				line-height: 1;
			}
		}

		input:checked~.state .svg {
			opacity: 1;
		}
	}

	&.p-image {
		.state img {
			opacity: 0;
			position: absolute;
			width: calc(1em + 2px);
			height: calc(1em + 2px);
			top: 0;
			top: calc((0% - (100% - 1em)) - 8%);
			left: 0;
			z-index: 0;
			text-align: center;
			line-height: normal;
			-webkit-transform: scale(0.8);
			-ms-transform: scale(0.8);
			transform: scale(0.8);
		}

		input:checked~.state img {
			opacity: 1;
		}
	}

	&.p-switch {
		input {
			min-width: 2em;
		}

		.state {
			position: relative;

			&:before {
				content: '';
				border: 1px solid $white-3;
				border-radius: 60px;
				width: 2em;
				box-sizing: unset;
				height: calc(1em + 2px);
				position: absolute;
				top: 0;
				top: calc((0% - (100% - 1em)) - 16%);
				z-index: 0;
				transition: all 0.5s ease;
			}

			label {
				text-indent: 2.5em;

				&:after,
				&:before {
					transition: all .5s ease;
					border-radius: 100%;
					left: 0;
					border-color: transparent;
					-webkit-transform: scale(0.8);
					-ms-transform: scale(0.8);
					transform: scale(0.8);
				}

				&:after {
					background-color: $white-3 !important;
				}
			}
		}

		input:checked~.state {
			&:before {
				border-color: $white-3;
			}

			label {
				&:before {
					opacity: 0;
				}

				&:after {
					background-color: $white-3 !important;
					left: 1em;
				}
			}
		}

		&.p-fill input:checked~.state {
			&:before {
				border-color: $white-3;
				background-color: $white-3 !important;
			}

			label {
				&:before {
					opacity: 0;
				}

				&:after {
					background-color: $white !important;
					left: 1em;
				}
			}
		}

		&.p-slim {
			.state:before {
				height: .1em;
				background: $white-3 !important;
				top: calc(50% - .1em);
			}

			input:checked~.state:before {
				border-color: $white-3;
				background-color: $white-3 !important;
			}
		}
	}

	&.p-has-hover input:hover~.state {
		&:not(.p-is-hover) {
			display: none;
		}

		&.p-is-hover {
			display: block;

			.icon1 {
				display: block;
			}
		}
	}

	&.p-has-focus input:focus~.state label:before {
		box-shadow: 0 0 3px 0 $white-3;
	}

	&.p-has-indeterminate input[type=checkbox]:indeterminate~.state {
		&:not(.p-is-indeterminate) {
			display: none;
		}

		&.p-is-indeterminate {
			display: block;

			.icon1 {
				display: block;
				opacity: 1;
			}
		}
	}

	&.p-toggle {
		.state {
			&.p-on {
				opacity: 0;
				display: none;
			}

			.icon1,
			.svg,
			img {
				opacity: 1;
				display: inherit;
			}

			&.p-off {
				opacity: 1;
				display: inherit;

				.icon1 {
					color: $white-3;
				}
			}
		}

		input:checked~.state {
			&.p-on {
				opacity: 1;
				display: inherit;
			}

			&.p-off {
				opacity: 0;
				display: none;
			}
		}
	}

	&.p-plain {

		input:checked~.state label:before,
		&.p-toggle .state label:before {
			content: none;
		}

		&.p-plain .icon1 {
			-webkit-transform: scale(1.1);
			-ms-transform: scale(1.1);
			transform: scale(1.1);
		}
	}

	&.p-round {
		.state label {

			&:after,
			&:before {
				border-radius: 100%;
			}
		}

		&.p-icon .state .icon1 {
			border-radius: 100%;
			overflow: hidden;

			&:before {
				-webkit-transform: scale(0.8);
				-ms-transform: scale(0.8);
				transform: scale(0.8);
			}
		}
	}

	&.p-curve .state label {

		&:after,
		&:before {
			border-radius: 20%;
		}
	}

	&.p-smooth {

		.icon1,
		.svg {
			transition: all 0.5s ease;
		}

		label {

			&:after,
			&:before {
				transition: all 0.5s ease;
			}
		}

		input:checked+.state {
			label:after {
				transition: all 0.3s ease;
			}

			.icon1,
			.svg,
			img {
				-webkit-animation: zoom .2s ease;
				animation: zoom 0.2s ease;
			}
		}

		&.p-default input:checked+.state label:after {
			-webkit-animation: zoom .2s ease;
			animation: zoom 0.2s ease;
		}

		&.p-plain input:checked+.state label:before {
			content: '';
			-webkit-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
			transition: all 0.5s ease;
		}
	}

	&.p-tada:not(.p-default) input:checked+.state {

		.icon1,
		.svg,
		img {
			-webkit-animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
			animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
			opacity: 1;
		}

		label {

			&:after,
			&:before {
				-webkit-animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
				animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
				opacity: 1;
			}
		}
	}

	&.p-jelly:not(.p-default) input:checked+.state {

		.icon1,
		.svg,
		img {
			-webkit-animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			opacity: 1;
		}

		label {
			&:after {
				-webkit-animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				opacity: 1;
			}

			&:before {
				-webkit-animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				opacity: 1;
				border-color: transparent;
			}
		}
	}

	&.p-rotate:not(.p-default) input:checked~.state {

		.icon1,
		.svg,
		img {
			-webkit-animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			opacity: 1;
		}

		label {
			&:after {
				-webkit-animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				opacity: 1;
			}

			&:before {
				-webkit-animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
				opacity: 1;
				border-color: transparent;
			}
		}
	}

	&.p-pulse:not(.p-switch) input:checked~.state label:before {
		-webkit-animation: pulse 1s;
		animation: pulse 1s;
	}

	input[disabled] {
		cursor: not-allowed;
		display: none;

		~* {
			opacity: 0.5;
		}
	}

	&.p-locked input {
		display: none;
		cursor: not-allowed;
	}

	input:checked~.state.p-primary {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	&.p-toggle .state.p-primary {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	input:checked~.state.p-primary-o label:after,
	&.p-toggle .state.p-primary-o label:after {
		background-color: transparent;
	}

	&.p-switch.p-slim input:checked~.state.p-primary:before {
		border-color: $primary;
		background-color: $primary !important;
	}

	input:checked~.state.p-info label:after,
	&.p-toggle .state.p-info label:after {
		background-color: $info !important;
	}

	input:checked~.state.p-info {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	&.p-toggle .state.p-info {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	input:checked~.state.p-info-o label:before,
	&.p-toggle .state.p-info-o label:before {
		border-color: $info;
	}

	input:checked~.state.p-info-o label:after,
	&.p-toggle .state.p-info-o label:after {
		background-color: transparent;
	}

	input:checked~.state.p-info-o {

		.icon1,
		.svg,
		svg {
			color: $info;
			stroke: $info;
		}
	}

	&.p-toggle .state.p-info-o {

		.icon1,
		.svg,
		svg {
			color: $info;
			stroke: $info;
		}
	}

	&.p-default:not(.p-fill) input:checked~.state.p-info-o label:after {
		background-color: $info !important;
	}

	&.p-switch {
		input:checked~.state.p-info:before {
			border-color: $info;
		}

		&.p-fill input:checked~.state.p-info:before {
			background-color: $info !important;
		}

		&.p-slim input:checked~.state.p-info:before {
			border-color: $info;
			background-color: $info !important;
		}
	}

	input:checked~.state.p-success label:after,
	&.p-toggle .state.p-success label:after {
		background-color: $success !important;
	}

	input:checked~.state.p-success {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	&.p-toggle .state.p-success {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	input:checked~.state.p-success-o label:before,
	&.p-toggle .state.p-success-o label:before {
		border-color: $success;
	}

	input:checked~.state.p-success-o label:after,
	&.p-toggle .state.p-success-o label:after {
		background-color: transparent;
	}

	input:checked~.state.p-success-o {

		.icon1,
		.svg,
		svg {
			color: $success;
			stroke: $success;
		}
	}

	&.p-toggle .state.p-success-o {

		.icon1,
		.svg,
		svg {
			color: $success;
			stroke: $success;
		}
	}

	&.p-default:not(.p-fill) input:checked~.state.p-success-o label:after {
		background-color: $success !important;
	}

	&.p-switch {
		input:checked~.state.p-success:before {
			border-color: $success;
		}

		&.p-fill input:checked~.state.p-success:before {
			background-color: $success !important;
		}

		&.p-slim input:checked~.state.p-success:before {
			border-color: $success;
			background-color: $success !important;
		}
	}

	input:checked~.state.p-warning label:after,
	&.p-toggle .state.p-warning label:after {
		background-color: $warning !important;
	}

	input:checked~.state.p-warning {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	&.p-toggle .state.p-warning {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	input:checked~.state.p-warning-o label:before,
	&.p-toggle .state.p-warning-o label:before {
		border-color: $warning;
	}

	input:checked~.state.p-warning-o label:after,
	&.p-toggle .state.p-warning-o label:after {
		background-color: transparent;
	}

	input:checked~.state.p-warning-o {

		.icon1,
		.svg,
		svg {
			color: $warning;
			stroke: $warning;
		}
	}

	&.p-toggle .state.p-warning-o {

		.icon1,
		.svg,
		svg {
			color: $warning;
			stroke: $warning;
		}
	}

	&.p-default:not(.p-fill) input:checked~.state.p-warning-o label:after {
		background-color: $warning !important;
	}

	&.p-switch {
		input:checked~.state.p-warning:before {
			border-color: $warning;
		}

		&.p-fill input:checked~.state.p-warning:before {
			background-color: $warning !important;
		}

		&.p-slim input:checked~.state.p-warning:before {
			border-color: $warning;
			background-color: $warning !important;
		}
	}

	input:checked~.state.p-danger label:after,
	&.p-toggle .state.p-danger label:after {
		background-color: $danger !important;
	}

	input:checked~.state.p-danger {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	&.p-toggle .state.p-danger {

		.icon1,
		.svg {
			color: $white;
			stroke: $white;
		}
	}

	input:checked~.state.p-danger-o label:before,
	&.p-toggle .state.p-danger-o label:before {
		border-color: $danger;
	}

	input:checked~.state.p-danger-o label:after,
	&.p-toggle .state.p-danger-o label:after {
		background-color: transparent;
	}

	input:checked~.state.p-danger-o {

		.icon1,
		.svg,
		svg {
			color: $danger;
			stroke: $danger;
		}
	}

	&.p-toggle .state.p-danger-o {

		.icon1,
		.svg,
		svg {
			color: $danger;
			stroke: $danger;
		}
	}

	&.p-default:not(.p-fill) input:checked~.state.p-danger-o label:after {
		background-color: $danger !important;
	}

	&.p-switch {
		input:checked~.state.p-danger:before {
			border-color: $danger;
		}

		&.p-fill input:checked~.state.p-danger:before {
			background-color: $danger !important;
		}

		&.p-slim input:checked~.state.p-danger:before {
			border-color: $danger;
			background-color: $danger !important;
		}
	}

	&.p-bigger {

		.icon1,
		.img,
		.svg {
			font-size: 1.2em !important;
			top: calc((0% - (100% - 1em)) - 35%) !important;
		}

		label {

			&:after,
			&:before {
				font-size: 1.2em !important;
				top: calc((0% - (100% - 1em)) - 35%) !important;
			}

			text-indent: 1.7em;
		}
	}
}

@-webkit-keyframes zoom {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}

@keyframes zoom {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}

@-webkit-keyframes tada {
	0% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
		-webkit-transform: scale(7);
		transform: scale(7);
	}

	38% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	55% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
	}

	72% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	81% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		-webkit-transform: scale(1.24);
		transform: scale(1.24);
	}

	89% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	95% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		-webkit-transform: scale(1.04);
		transform: scale(1.04);
	}

	100% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes tada {
	0% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
		-webkit-transform: scale(7);
		transform: scale(7);
	}

	38% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	55% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
	}

	72% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	81% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		-webkit-transform: scale(1.24);
		transform: scale(1.24);
	}

	89% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	95% {
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		-webkit-transform: scale(1.04);
		transform: scale(1.04);
	}

	100% {
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes jelly {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	30% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}

	40% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}

	50% {
		-webkit-transform: scale3d(0.85, 1.15, 1);
		transform: scale3d(0.85, 1.15, 1);
	}

	65% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}

	75% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}

	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes jelly {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	30% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}

	40% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}

	50% {
		-webkit-transform: scale3d(0.85, 1.15, 1);
		transform: scale3d(0.85, 1.15, 1);
	}

	65% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}

	75% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}

	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@-webkit-keyframes rotate {
	0% {
		opacity: 0;
		-webkit-transform: translateZ(-200px) rotate(-45deg);
		transform: translateZ(-200px) rotate(-45deg);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateZ(0) rotate(0);
		transform: translateZ(0) rotate(0);
	}
}

@keyframes rotate {
	0% {
		opacity: 0;
		-webkit-transform: translateZ(-200px) rotate(-45deg);
		transform: translateZ(-200px) rotate(-45deg);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateZ(0) rotate(0);
		transform: translateZ(0) rotate(0);
	}
}

@-webkit-keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 $white-3;
	}

	100% {
		box-shadow: 0 0 0 1.5em transparent;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 $white-3;
	}

	100% {
		box-shadow: 0 0 0 1.5em transparent;
	}
}

@media print {
	.pretty .state {
		.icon1 {
			accent-color: exact;
			-webkit-print-color-adjust: exact;
			print-color-adjust: exact;
		}

		label {

			&:after,
			&:before {
				accent-color: exact;
				-webkit-print-color-adjust: exact;
				print-color-adjust: exact;
			}
		}

		&:before {
			accent-color: exact;
			-webkit-print-color-adjust: exact;
			print-color-adjust: exact;
		}
	}
}