@charset "UTF-8";

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;

  td,
  th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  td.dataTables_empty,
  th.dataTables_empty {
    text-align: center;
  }

  &.nowrap {

    th,
    td {
      white-space: nowrap;
    }
  }
}

div.dataTables_wrapper div {
  &.dataTables_length {
    label {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
    }

    select {
      width: 75px;
      display: inline-block;
    }
  }

  &.dataTables_filter {
    text-align: right;

    label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
    }

    input {
      margin-left: 0.5em;
      display: inline-block;
      width: auto;
    }
  }

  &.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
  }

  &.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;

    ul.pagination {
      margin: 2px 0;
      white-space: nowrap;
    }
  }

  &.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }
}

table.dataTable thead {
  >tr> {
    th {

      &.sorting_asc,
      &.sorting_desc,
      &.sorting {
        padding-right: 30px;
      }
    }

    td {

      &.sorting_asc,
      &.sorting_desc,
      &.sorting {
        padding-right: 30px;
      }
    }

    th:active,
    td:active {
      outline: none;
    }
  }

  .sorting,
  .sorting_asc,
  .sorting_desc,
  .sorting_asc_disabled,
  .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
  }

  .sorting:after,
  .sorting_asc:after,
  .sorting_desc:after,
  .sorting_asc_disabled:after,
  .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: "Glyphicons Halflings";
    opacity: 0.5;
  }

  .sorting:after {
    opacity: 0.2;
    content: "";

    /* sort */
  }

  .sorting_asc:after {
    opacity: 0.5;
    content: "";

    /* sort-by-attributes */
  }

  .sorting_desc:after {
    opacity: 0.5;
    content: "";

    /* sort-by-attributes-alt */
  }

  .sorting_asc_disabled:after,
  .sorting_desc_disabled:after {
    color: $white;
  }
}

div {
  &.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
  }

  &.dataTables_scrollBody>table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    > {
      thead {

        .sorting:after,
        .sorting_asc:after,
        .sorting_desc:after {
          display: none;
        }
      }

      tbody>tr:first-child> {

        th,
        td {
          border-top: none;
        }
      }
    }
  }

  &.dataTables_scrollFoot>.dataTables_scrollFootInner {
    box-sizing: content-box;

    >table {
      margin-top: 0 !important;
      border-top: none;
    }
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div {

    &.dataTables_length,
    &.dataTables_filter,
    &.dataTables_info,
    &.dataTables_paginate {
      text-align: center;
    }
  }
}

table {
  &.dataTable.table-condensed {
    >thead>tr>th {
      padding-right: 20px;
    }

    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after {
      top: 6px;
      right: 6px;
    }
  }

  &.table-bordered.dataTable {
    border-right-width: 0;

    th,
    td {
      border-left-width: 0;
    }

    th:last-child,
    td:last-child {
      border-right-width: 1px;
    }

    tbody {

      th,
      td {
        border-bottom-width: 0;
      }
    }
  }
}

div {
  &.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
  }

  &.table-responsive>div.dataTables_wrapper>div.row {
    margin: 0;

    >div[class^=col-] {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}