/*-------- Timeline -------*/

.timeline {
  border-collapse: collapse;
  border-spacing : 0;
  display        : table;
  margin-bottom  : 50px;
  position       : relative;
  table-layout   : fixed;
  width          : 100%;

  .time-show {
    margin-bottom: 30px;
    margin-right : -75px;
    margin-top   : 30px;
    position     : relative;

    a {
      color: $white;
    }
  }

  &:before {
    background-color: $black-3;
    bottom          : 0px;
    content         : "";
    left            : 50%;
    position        : absolute;
    top             : 30px;
    width           : 2px;
    z-index         : 0;
  }

  .timeline-icon {
    -webkit-border-radius: 50%;
    background           : $dark;
    border-radius        : 50%;
    color                : $white;
    display              : block;
    height               : 21px;
    left                 : -54px;
    margin-top           : -11px;
    position             : absolute;
    text-align           : center;
    top                  : 50%;
    width                : 21px;

    i {
      color: $background;
    }
  }

  .time-icon:before {
    font-size : 16px;
    margin-top: 5px;
  }
}

h3.timeline-title {
  color         : $dark;
  font-size     : 20px;
  font-weight   : 400;
  margin        : 0 0 5px;
  text-transform: uppercase;
}

.timeline-item {
  display: table-row;

  &:before {
    content: "";
    display: block;
    width  : 50%;
  }

  .timeline-desk .popover-arrow {
    border-bottom: 8px solid transparent;
    border-right : 8px solid $white !important;
    border-top   : 8px solid transparent;
    display      : block;
    height       : 0;
    left         : -7px;
    margin-top   : -10px;
    position     : absolute;
    top          : 50%;
    width        : 0;
  }

  &.alt {
    &:after {
      content: "";
      display: block;
      width  : 50%;
    }

    .timeline-desk {
      .arrow-alt {
        border-bottom: 8px solid transparent;
        border-left  : 8px solid $white !important;
        border-top   : 8px solid transparent;
        display      : block;
        height       : 0;
        left         : auto;
        margin-top   : -10px;
        position     : absolute;
        right        : -7px;
        top          : 50%;
        width        : 0;
      }

      .album {
        float     : right;
        margin-top: 20px;

        a {
          float      : right;
          margin-left: 5px;
        }
      }
    }

    .timeline-icon {
      left : auto;
      right: -57px;
    }

    &:before {
      display: none;
    }

    .panel {
      margin-left : 0;
      margin-right: 45px;

      .panel-body p+p {
        margin-top: 10px !important;
      }
    }

    h4,
    p,
    .timeline-date {
      text-align: right;
    }
  }
}

.timeline-desk {
  display       : table-cell;
  vertical-align: top;
  width         : 50%;

  h4 {
    font-size  : 16px;
    font-weight: 500;
    margin     : 0;
  }

  .panel {
    background   : $white;
    display      : block;
    margin-bottom: 5px;
    margin-left  : 45px;
    position     : relative;
    text-align   : left;
    padding      : 20px;
  }

  h5 span {
    color        : $white-3;
    display      : block;
    font-size    : 12px;
    margin-bottom: 4px;
  }

  p {
    color        : $black;
    font-size    : 14px;
    margin-bottom: 0;
  }

  .album {
    margin-top: 12px;

    a {
      float       : left;
      margin-right: 5px;
    }

    img {
      height       : 50px;
      width        : auto;
      border-radius: 3px;
    }
  }

  .notification {
    background: none repeat scroll 0 0 $white;
    margin-top: 20px;
    padding   : 8px;
  }
}

/** timeline left **/

.timelineleft {
  position  : relative;
  margin    : 0 0 30px 0;
  padding   : 0;
  list-style: none;

  &:before {
    content      : '';
    position     : absolute;
    top          : 0;
    bottom       : 0;
    width        : 2px;
    background   : $black-3;
    left         : 31px;
    margin       : 0;
    border-radius: 2px;
  }

  >li {
    position     : relative;
    margin-right : 10px;
    margin-bottom: 1.5rem;
  }
}

.timelinelft>li:before {
  content: " ";
  display: table;
}

.timelineleft> {
  li:after {
    content: " ";
    display: table;
  }

  .timeleft-label>span {
    font-weight     : 600;
    padding         : 5px;
    display         : inline-block;
    background-color: $white;
    color           : $white;
    border-radius   : 4px;
  }

  li {
    &:after {
      clear: both;
    }

    >.timelineleft-item {
      -webkit-box-shadow: 0 1px 1px $black-1;
      box-shadow        : 0 0 0 1px $black-1, 0 8px 16px 0 $black-2;
      border-radius     : 3px;
      margin-top        : 0;
      background        : $white;
      color             : $dark;
      margin-left       : 60px;
      margin-right      : 15px;
      padding           : 0;
      position          : relative;
      overflow          : hidden;

      > {
        .time {
          color    : $white-3;
          float    : right;
          padding  : 10px;
          font-size: 12px;
        }

        .timelineleft-header {
          margin       : 0;
          color        : $white-3;
          border-bottom: 1px solid $background;
          padding      : .75rem 1.25rem;
          font-size    : 16px;
          line-height  : 1.1;

          >a {
            font-weight: 500;
            font-size  : 14px;
          }
        }
      }
    }
  }
}

.timelineleft-body img {
  width : 150px;
  height: 100px;
}

.timelineleft>li>.timelineleft-item> {

  .timelineleft-body,
  .timelineleft-footer {
    padding: .75rem 1.25rem;
  }
}

.timelineleft-body {
  font-size: 14px;
}

.timelineleft>li> {

  .fa,
  .glyphicon,
  .ion {
    width        : 30px;
    height       : 30px;
    font-size    : 15px;
    line-height  : 30px;
    position     : absolute;
    color        : $white;
    background   : $background;
    border-radius: 50%;
    text-align   : center;
    left         : 18px;
    top          : 0;
  }
}