.show.image-zoom {
  width: 350px;
  z-index: 99;
  text-align: center;
  margin: 2rem auto;
  height: 390px;

  &#show-img {
    height: 400px;
    width: 100%;
  }
}

.image-zoom-container {
  img {
    max-width: initial !important;
  }

  margin: 30px 19px 30px 30px;
  border: 1px solid $border;
}

*::-ms-backdrop,
.image-zoom-container img {
  max-width: inherit !important;
}

*::-ms-backdrop .show.image-zoom {
  width: 400px;
  height: 400px;
  z-index: 999;
}

#show-img {
  height: 400px;
  width: 100%;
}

.small-img {
  height: 137px;
  margin-top: 30px;
  position: relative;
  border-top: 1px solid $border;

  .icon-left,
  .icon-right {
    width: 12px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  .icon-left {
    transform: rotate(180deg);
    left: 45px;
  }

  .icon-right {
    right: 45px;
  }

  .icon-left:hover,
  .icon-right:hover {
    opacity: .5;
  }
}

.small-container {
  width: 317px;
  height: 70px;
  overflow: hidden;
  position: absolute;
  margin: 34px auto;
  left: 70px;
  right: 70px;

  div {
    width: 800%;
    position: relative;
  }

  .show-small-img {
    width: 70px;
    height: 70px;
    margin-right: 4px;
    margin-left: 4px;
    cursor: pointer;
    float: left;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .small-container {
    width: 239px !important;
    height: 70px;
    overflow: hidden;
  }
}

@media (max-width: 767px) and (min-width: 567px) {
  .small-container {
    width: 300px !important;
    height: 70px;
    overflow: hidden;
  }
}

@media (max-width: 566px) and (min-width: 480px) {
  .small-container {
    width: 238px !important;
    height: 70px;
    overflow: hidden;
  }
}

@media (max-width: 565px) and (min-width: 319px) {
  .small-container {
    width: 160px !important;
    height: 70px;
    overflow: hidden;
  }

  .show.image-zoom {
    width: 269px;
    text-align: center;
    height: 359px;
  }
}

@media (max-width: 565px) {
  .show.image-zoom {
    width: 200px;
    text-align: center;
    height: 359px;
  }

  .small-container {
    width: 80px !important;
    height: 70px;
    overflow: hidden;
  }
}