@charset "UTF-8";

/*------------------------------------------------------------------

 * @Plugin Title :  PrimoSlider - Bootstrap Carousel Slider (No Jquery Dependency) 
 * @version      :  V.1.0(26/May/2022)
 * @author       :  Spruko Technologies Private Limited 
 * @autorURL     :  https://spruko.com/

-------------------------------------------------------------------*/
.primo-slider{
    .carousel-item {
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

    .carousel-item-next,
    .carousel-item-prev,
    .carousel-item.active {
        display: flex;
    }

    .carousel-fade .active {

        &.carousel-item-start,
        &.carousel-item-end {
            transition: all 0.5s ease !important;
        }
    }

    .slide-show-image .carousel-item.active {
        display: flex !important;
        width: 100%;
    }

    .carousel-item {
        img {
            margin: 0 auto;
            top: 0;
            border-radius: 5px;
            width: 100%;
            object-fit: cover;
        }

        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: transparent;
        }

        &.carousel-item-start::before {
            z-index: -1;
        }

        &.active {

            .primo-slider-fadeInLeft>*,
            .primo-slider-fadeInRight>* {
                transform: translateX(0%);
                opacity: 1;
            }

            .primo-slider-fadeInTop>*,
            .primo-slider-fadeInBottom>* {
                transform: translateY(0%);
                opacity: 1;
            }
        }

        iframe {
            z-index: 9;
            height: 35.3em;
            width: 100%;
        }

        video {
            width: 100%;
        }
    }

    .carousel-indicators {
        margin: 0 !important;

        [data-bs-target] {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 100px;
            height: auto;
            padding: 0;
            margin-right: 3px;
            margin-left: 3px;
            cursor: pointer;
            background-color: transparent;
            background-clip: padding-box;
            border: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            transition: opacity 0.6s ease;
        }
    }

    [data-bs-dots=true] .carousel-indicators {
        margin: auto !important;
    }

    .carousel-caption {
        font-size: 18px;
        font-weight: 400;
        background-color: $black-2;
        border-radius: 0px 5px;
        padding-bottom: 6px;
    }

    .carousel-control-next,
    .carousel-control-prev {
        bottom: inherit;
        top: 50%;
        transform: translateY(-50%);
    }

    .carousel .primoslider-icon {
        color: $white-8;
        border: 1px solid $black-3;
        background: $black-5;
        padding: 5px;
        width: 38px;
        height: 38px;
        border-radius: 50px;
    }

    /* ###### SLIDER-STYLES ###### */

    .slider-wrap {
        position: relative;
        margin: auto;
        height: 6.5rem;
        cursor: pointer;
        transition: all 0.5s ease;
        max-width: 100%;
    }

    .carousel {
        &:not([data-bs-dots=true]) .slider {
            position: absolute;
            top: 0;
            left: 0;
            height: 6.5rem;
            width: 100%;
            overflow: scroll;
            transition: all 0.5s ease;
            touch-action: none;
            -ms-touch-action: none;
        }

        &[data-bs-dots=true] .slider {
            position: absolute;
            top: -40px;
            left: 0;
            height: 100%;
            width: 100%;
            transition: all 0.5s ease;
        }

        &:not([data-bs-dots=true]) .slider-inner {
            position: absolute;
            display: flex;
            justify-content: center;
            top: 0;
            left: 0;
            width: fit-content;
            height: 6.5rem;
            transform: translateX(0);
            transition: all 1s ease;
        }

        &[data-bs-dots=true] .slider-inner {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            top: 0;
            left: 0;
            width: fit-content;
            height: auto;
            transform: translateX(0);
            transition: all 1s ease;
        }
    }

    .slider {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .slider-wrap {
        .thumb-carousel-control-prev {
            left: 0;
            z-index: 9999;
            height: 3rem;
            top: 30px;
            width: 2rem;
            position: absolute;
        }

        .thumb-carousel-control-next {
            position: absolute;
            right: 0;
            z-index: 9999;
            height: 3rem;
            top: 30px;
            width: 2rem;
        }
    }

    .product-slider .slider-wrap {
        margin: 10px 0 0 0;
        padding: 0;
    }

    .slider-inner {

        img,
        video {
            width: 100px;
            border-radius: 5px;
            border: 1px solid $black-5;
        }
    }

    .carousel-slide-1 .slider-inner img {
        height: 100px;
        width: 100px;
    }

    [data-bs-dots=true] .slider-wrap {
        height: 0 !important;
    }

    .carousel:not([data-bs-dots=true]) .slider {
        &::before {
            content: "";
            display: block;
            left: 0;
            background: linear-gradient(to left, transparent 0%, $white-7 100%);
            bottom: 0px;
            position: absolute;
            width: 50px;
            height: 100%;
            z-index: 3;
        }

        &::after {
            content: "";
            display: block;
            right: 0;
            background: linear-gradient(to right, transparent 0%, $white-7 100%);
            bottom: 0px;
            position: absolute;
            width: 50px;
            height: 100%;
            z-index: 3;
        }
    }

    .slider .carousel-caption {
        background-color: transparent;
        display: none !important;
    }

    .fixed-slider>.slider-inner {
        transform: translateX(0) !important;
        margin: auto !important;
    }

    @media (min-width: 576px) {

        [data-bs-overlay=true] .thumb,
        [data-bs-overlay-top=true] .thumb {
            background-color: transparent;
            opacity: 0.7;
        }

        .carousel {
            &[data-bs-overlay-top=true] .slider {

                &::after,
                &::before {
                    background: linear-gradient(to left, transparent 0%, transparent 100%) !important;
                }
            }

            &[data-bs-overlay=true] .slider {

                &::after,
                &::before {
                    background: linear-gradient(to left, transparent 0%, transparent 100%) !important;
                }
            }
        }

        [data-bs-overlay=true] .slider-wrap {
            height: 0 !important;
            top: -100px;
        }

        [data-bs-overlay-top="true"] .slider-wrap {
            height: 0 !important;
        }
    }

    /* ###### THUMB-STYLES ###### */

    .thumb {
        margin: 0 0.6rem;
    }

    .thumb-carousel-control-next.btn-disabled,
    .thumb-carousel-control-prev.btn-disabled {
        display: none !important;
    }

    .carousel {

        .thumb-carousel-control-prev,
        .thumb-carousel-control-next {
            height: 1.7rem;
            top: 50px;
            transform: translateY(-50%);
            width: 1.7rem;
            opacity: 0.6;
            text-align: center;
            display: grid;
        }

        .thumb-carousel-control-prev .primoslider-icon,
        .thumb-carousel-control-next .primoslider-icon {
            color: $white-8;
            margin: auto 0;
            width: 30px;
            height: 30px;
            font-size: 1.2rem;
        }
    }

    .thumb.item.active {

        img,
        video {
            border: 2px solid $danger;
            box-shadow: 0px 1px 9px 4px rgba(0, 0, 0, 0.15);
        }
    }

    .fixed-slider+ {

        .thumb-carousel-control-prev,
        .thumb-carousel-control-next {
            display: none !important;
        }

        .thumb-carousel-control-prev {
            display: none !important;

            +.thumb-carousel-control-next {
                display: none !important;
            }
        }
    }

    .thumb {
        position: relative;
    }

    [data-bs-video="true"] .videothumb::after {
        content: '';
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXBsYXktY2lyY2xlIj48Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMCI+PC9jaXJjbGU+PHBvbHlnb24gcG9pbnRzPSIxMCA4IDE2IDEyIDEwIDE2IDEwIDgiPjwvcG9seWdvbj48L3N2Zz4=);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;

        /* filter: invert(1); */
        background-repeat: no-repeat;
        width: 1.5rem;
        height: 1.5rem;
    }

    .carousel-indicator-custom-styles .thumb.item.active::before {
        content: '';
        width: 13px;
        height: 13px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        border: transparent 6px dashed;
        border-bottom: 6px solid $danger;
    }

    @media (max-width: 480px) {
        .carousel:not([data-bs-dots=true]) .slider {

            &::before,
            &::after {
                display: none;
            }
        }
    }

    .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 6.5rem;
        top: 1%;
        overflow: hidden;
    }

    .slide-show-image {
        display: flex !important;
        width: 100%;
        border-radius: 5px;
    }

    #carousel .slide-show-image.carousel-inner {
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        border-radius: 5px;
    }

    .slide-items {
        height: 5rem !important;
        margin: 0 5px !important;
        width: 100% !important;
        display: flex;
    }

    @media (max-width: 768px) {
        .slide-show-image {
            display: flex !important;
            width: 100%;

            .carousel-item.active {
                display: flex !important;
                width: 100%;
            }
        }

        #carousel .slide-show-image.carousel-inner {
            overflow: hidden;
            width: 100%;
            margin: 0 auto;
            border-radius: 5px;
        }

        .slide-items {
            height: 3rem !important;
        }

        .product-slider .thumbcarousel .carousel-item .thumb {
            margin: 0 3px !important;
        }

        .carousel-item iframe {
            height: 28em !important;
        }
    }

    [data-bs-dots=true] .dots {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        opacity: 0.7;

        &.active {
            background-color: $danger;
        }
    }

    .carousel-control-prev,
    .carousel-control-next,
    .thumb-carousel-control-prev,
    .thumb-carousel-control-next {
        z-index: 99 !important;
    }

    .bg-primary-gradient {
        background: $primary;
    }

    .bg-background-color .content-text {
        position: relative;
        z-index: 10;
    }

    .demo-primo-slider {
        margin-top: 3rem;
    }

    /* ###### PRIMOSLIDER ANIMATIONS ###### */

    .primo-slider-fadeInLeft>* {
        transform: translateX(-100%);
        opacity: 0;
        transition: all 0.5s ease;
    }

    .primo-slider-fadeInRight>* {
        transform: translateX(100%);
        opacity: 0;
        transition: all 0.5s ease;
    }

    .primo-slider-fadeInTop>* {
        transform: translateY(-100%);
        opacity: 0;
        transition: all 0.5s ease;
    }

    .primo-slider-fadeInBottom>* {
        transform: translateY(100%);
        opacity: 0;
        transition: all 0.5s ease;
    }
}

.rtl {
    .primo-slider {

        .carousel-control-next {
            right: initial;
            left: 0;
            transform: rotate(180deg);
        }

        .carousel-control-prev {
            right: 0;
            left: initial;
            transform: rotate(180deg);
        }

        .slider-wrap {
            .thumb-carousel-control-next {
                right: initial;
                left: 0;
                transform: rotate(180deg) translateY(50%) !important;
            }

            .thumb-carousel-control-prev {
                right: 0;
                left: initial;
                transform: rotate(180deg) translateY(50%) !important;
            }
        }

        .carousel:not(.carousel-fade) {

            .active.carousel-item-start,
            .carousel-item-prev:not(.carousel-item-end) {
                transform: translateX(100%) !important;
            }

            .active.carousel-item-end {
                transform: translateX(-100%) !important;
            }

            .carousel-item-next:not(.carousel-item-start) {
                transform: translateX(-100%) !important;
                transform: translateX(-100%) !important;
            }
        }
    }
}