$background: #f6f7fb;
$border: #e8ebf3;
$color:#605e7e;
$color-2:#55606c;

/*primary*/
$primary:var(--primary-bg-color); 
$primary-hover:var(--primary-bg-hover);
$primary-shadow:var(--primary-shadow);
$primary-gradient:linear-gradient(120deg, var(--primary-9) 0%, var(--primary-8)100%);
$primary-1: var(--primary-1);
$primary-2: var(--primary-2);
$primary-3: var(--primary-3);
$primary-4: var(--primary-4);
$primary-5: var(--primary-5);
$primary-6: var(--primary-6);
$primary-7: var(--primary-7);
$primary-8: var(--primary-8);
$primary-9: var(--primary-9);

/*Color variables*/
$secondary:var(--secondary-color);
$success:var(--success);
$info:var(--info);
$warning:var(--warning);
$danger:var(--danger);
$blue:var(--blue);
$yellow:var(--yellow);
$lime:var(--lime);
$teal:var(--teal);
$purple:var(--purple);
$pink:var(--pink);
$orange:var(--orange);
$dark:var(--dark);
$indigo:var(--indigo);
$white:var(--white);
$black:var(--black);


/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);
$black-15:rgba(0, 0, 0, 0.15);


/***********dark-theme**************/

$dark-theme: var(--dark-theme);
$dark-body: var(--dark-body);
$dark-border:var(--dark-border);
$dark-color:var(--dark-color);
$dark-shadow:var(--dark-shadow);

	

