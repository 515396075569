/* jQuery Countdown styles 2.0.0. */

.countdown-rtl {
	direction: rtl;
}

.countdown-holding span {
	color: $white-3;
}

.countdown-row {
	clear: both;
	width: 100%;
	padding: 0px 2px;
	text-align: center;
}

.countdown {
	border-radius: 5px;
	margin-bottom: 20px;
	max-width: 500px;
	margin: 25px auto 30px auto;

	li {
		display: inline-block;
	}

	.number {
		font-size: 35px;
		font-weight: bold;
		color: $white;
		width: 80px;
		height: 80px;
		border-radius: 7px;
		line-height: 2.3;
		text-align: center;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		background: $primary-gradient;
	}

	span {
		display: block;
	}

	.time {
		font-size: 20px;
		color: $color;
		padding-top: 12px;
	}
}

@media (max-width: 991px) {
	.countdown li {
		margin: 20px 10px 10px 10px;
	}

	.is-countdown {
		margin-top: 20px;
	}
}

@media (min-width: 992px) {
	.countdown li {
		margin: 30px 15px 5px 15px;
	}
}